import React from "react"
import { graphql } from "gatsby"

import SEO from "src/components/Element/seo"
import svg_osaka_chishima_zaidan from "src/../static/images/osaka_sozo_chishima_zaidan_logo.svg"

import "./index.css"

const About = ({data, location}) => {
    const siteTitle = data.site.siteMetadata.title
    const config = data.allStrapiConfig.edges[0].node

    return (
        <>
            <SEO
                title={"千のおうちとは？ - " + siteTitle}
                description={"千のおうちについて。" + config.site_description}
            />
            <div id="about" className="main-content">
                <div className="article-main">
                    <div className="am-wrapper">
                        <div className="page-content">
                            <h1 className="page-content-title">千のおうちとは？</h1>
                            <div className="page-content-text">
                                <div dangerouslySetInnerHTML={{__html: config.about_text}} />
                                <div className="am-tags josei">
                                    <p>助成 | おおさか創造千島財団</p>
                                    <p>
                                        <a href="http://www.chishimatochi.info/found/" target="_blank">
                                            <img className="chishima-logo" src={svg_osaka_chishima_zaidan} alt="おおさか創造千島財団" />
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default About

export const pageQuery = graphql`
    query {
        site {
            siteMetadata {
                title
            }
        }
        allStrapiConfig {
            edges {
                node {
                    about_text,
                    site_description
                }
            }
        }
    }
`
